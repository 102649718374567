import React from "react";

export default function Contact() {
  return (
    <div id="contact" className="w-full bg-white py-16 px-4">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 gap-4">
        <div>
          <h2 className="text-gray-700 font-bold text-center md:text-left">CONTACT US</h2>
          <form className="mt-4">
            <input className="w-full p-2 mb-4 border border-gray-300 rounded" type="text" placeholder="Your Name" />
            <input className="w-full p-2 mb-4 border border-gray-300 rounded" type="email" placeholder="Your Email" />
            <textarea className="w-full p-2 mb-4 border border-gray-300 rounded" placeholder="Your Message"></textarea>
            <button className="w-full p-2 bg-[#b6eaf2] text-gray-700 rounded">Submit</button>
          </form>
        </div>
        <div className="mt-8 md:mt-0">
          <iframe
            title="Our Headquarters"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2629.306167111883!2d2.3709665761237786!3d48.77604490649833!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e673e44f552ee9%3A0xbcb00beef089f577!2sICO%20Studio!5e0!3m2!1sfr!2sfr!4v1706129754422!5m2!1sfr!2sfr"
            className="w-full"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  );

}