import React from "react";
import Typed from 'react-typed';
const Hero = () => {
    return (
        <div className={'text-gray-700 bg-["url(../assets/cloud.svg")]'}>
            <div className={'max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'}>
                <p className={'text-[#000000] font-bold p-2'}>FAST WEB DEVELOPMENT & QUALITY</p>
                <h1 className={'md:text-7xl sm:text-6xl text-4xl font-bold md:py-6'}>Your imagination is our creation</h1>
                <div className={'flex justify-center items-center'}>
                    <p className={'md:text-5xl sm:text-4xl text-xl font-bold py-4'}>
                        Fast, serious developing for
                    </p>
                    <Typed
                      className={'md:text-5xl sm:text-4xl text-xl font-bold md:pl-2'}
                        strings={['PHP', 'HTML', 'CSS', 'JS']}
                        typeSpeed={135}
                        backSpeed={160}
                        loop
                    />
                </div>
                <p className={'md:text-2xl text-xl font-bold text-gray-500'}>Talk with us about your ideas! Our team will work soon as possible on your projects.</p>
                <button className={'bg-[#ffffff] w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-black'}>Chose a Service</button>
            </div>
        </div>
    )
}

export default Hero
