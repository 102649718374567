import React from "react";
import Webservice from "../assets/Webservice.png"
const Presentation = () => {
    return (
        // What We Do
        <div className={'w-full border-gray-700 py-16 px-4'}>
           <div className={'max-w-[1240px] mx-auto grid md:grid-cols-2'}>
               <img className={'w-[500px] mx-auto my-4'} src={Webservice} alt={'/'}/>
               <div className={'flex flex-col justify-center'}>
                   <p className={'text-[#b6eaf2] font-bold'}>OUR MISSIONS</p>
                   <h1 className={'md:text-4xl sm:text-3xl text-2xl font-bold py-2'}>What We Do ?</h1>
                   <p>At ICO STUDIO, we excel in crafting bespoke websites using cutting-edge programming languages. Our expert team seamlessly combines creativity with technical proficiency to deliver visually stunning and functional websites tailored to your brand.</p>
                   <button className={'bg-white w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-black'}>Get Started</button>
               </div>
           </div>
        </div>
    )
}

export default Presentation
