import React from 'react';
import {
  FaSnapchatSquare,
  FaFacebookSquare,
  FaGithubSquare,
  FaInstagram,
  FaTwitterSquare,
} from 'react-icons/fa';

const Footer = () => {
  return (
    <div className='max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-900' id='footer'>
      <div>
        <h1 className='w-full text-3xl font-bold text-[#000]'>ICO STUDIO</h1>
        <p className='py-4'></p>
        <div className='flex justify-between md:w-[75%] my-6'>
            <FaFacebookSquare size={30} />
            <a href="https://www.instagram.com/icostudio_official?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank" rel="noopener noreferrer">
              <FaInstagram size={30} />
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
            <FaTwitterSquare size={30} />
            </a>
            <a href="https://github.com/yassdah21" target="_blank" rel="noopener noreferrer">
            <FaGithubSquare size={30} />
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
            <FaSnapchatSquare size={30} />
            </a>
        </div>
      </div>
      <div className='lg:col-span-2 flex justify-between mt-6'>
    <div>
        <h6 className='font-medium text-gray-900'>Solutions</h6>
        <ul>
            <li className='py-2 text-sm'>Web design</li>
            <li className='py-2 text-sm'>Web hosting</li>
            <li className='py-2 text-sm'>E-commerce</li>
            <li className='py-2 text-sm'>All-In-One</li>
        </ul>
    </div>
    <div>
        <h6 className='font-medium text-gray-900'>Support</h6>
        <ul>
            <li className='py-2 text-sm'>Pricing</li>
            <li className='py-2 text-sm'>Documentation</li>
            <li className='py-2 text-sm'>Guides</li>
        </ul>
    </div>
    <div>
        <h6 className='font-medium text-gray-900'>Company</h6>
        <ul>
            <li className='py-2 text-sm'>About</li>
            <li className='py-2 text-sm'>Blog</li>
        </ul>
    </div>
    <div>
        <h6 className='font-medium text-gray-900'>Legal</h6>
        <ul>
            <li className='py-2 text-sm'>Claim</li>
            <li className='py-2 text-sm'>Policy</li>
            <li className='py-2 text-sm'>Terms</li>
        </ul>
    </div>
      </div>
    </div>
  );
};

export default Footer;
