import React from "react";

export default function Companies() {
  return (
    <div className="w-full bg-gray-100 py-16 px-4">
      <div className="max-w-[1240px] mx-auto">
        <h2 className="text-gray-700 font-bold text-center">COMPANIES WE WORK WITH</h2>
        <p className="text-center mt-4 mb-8 text-gray-700">Our clients trust us to deliver quality work on time. We pride ourselves on our commitment to excellence and customer satisfaction.</p>
        <div className="flex flex-wrap justify-center">
          <div className="m-4">
            <img className="h-24 w-auto" src="https://www.u-pec.fr/uas/web/LOGO/2017_11_27_Logotype-UPEC_rvb.svg" alt="Company 1" />
          </div>
          <div className="m-4">
            <img className="h-24 w-auto" src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/1024px-Google_%22G%22_logo.svg.png" alt="Company 2" />
          </div>
          <div className="m-4">
            <img className="h-24 w-auto" src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/4a/Amazon_icon.svg/2500px-Amazon_icon.svg.png" alt="Company 3" />
          </div>
          {/* Add more logos as needed */}
        </div>
      </div>
    </div>
  );
}