import React from "react";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Presentation from "./components/Presentation";
import Sliders from "./components/Sliders";
import Footer from "./components/Footer";
import Contact from "./components/Contact";

function App() {
  return (
    <div>
      <Navbar />
      <Hero />
      <Presentation />
      <Sliders />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
